<template>
  <header class="header-area">
    <div class="header-top">
      <figure class="logo bonauf-app-logo" @click="gotoHome">
        <img
          :src="`${imageDomain}/assets/img/app/logo2.png`"
          alt="bonauf-app-logo"
          title=""
        />
      </figure>
      <button class="btn-menu" @click="showMenu"></button>
    </div>
    <div class="header-body">
      <div class="sape1"></div>
      <div class="container">
        <!-- <div class="d-flex">
          <h3>או נהנים מעסקאות בדקה ה-90?</h3>
          <i class="fa-solid fa-xmark closeFilter" v-if="expand" @click="closeOptionPanel"></i>
        </div> -->
        <div class="row">
          <div class="col-lg-12 pb-2">
            <div class="form-group" @click="openOptionPanel">
              <b-form-select
                class="form-control"
                v-model="selectedDestination"
                :options="optionsDestination"
                :disabled="!expand"
              />
              <div :class="[!expand ? 'collapsed': 'open', 'close' ]">
                <i class="fa-solid fa-angle-down" v-if="!expand && !showInitAllOption" @click="initializeAllFilterOption"></i>
                <i class="fa-solid fa-xmark mr-2" v-if="!expand && showInitAllOption" @click="initializeAllFilterOption"></i>
                <i class="fa-solid fa-xmark" v-else-if="showInitDestination" @click="initializeDestOption"></i>
              </div>
            </div>
          </div>
          <div class="col-lg-12 pb-2" v-if="expand">
            <div class="form-group">
              <b-form-select
                class="form-control"
                v-model="selectedMonth"
                :options="optionsMonth"
              />
              <div class="close" v-if="showInitMonth">
                <i class="fa-solid fa-xmark" @click="initializeMonthOption"></i>
              </div>
            </div>
          </div>
          <div class="col-lg-12 pb-2" v-if="expand">
            <div class="form-group">
              <b-form-select
                class="form-control"
                v-model="selectedDealCategory"
                :options="optionsCategory"
              />
              <div class="close" v-if="showInitCategory">
                <i class="fa-solid fa-xmark" @click="initializeCategoryOption"></i>
              </div>
            </div>
          </div>
          <div class="col-lg-12 pb-2" v-if="expand">
            <div class="form-group d-flex">
              <div class="form-group col-6 pr-0">
                <input v-model="fromPrice" class="form-control" :placeholder="`ממחיר (${rangePriceDeal.min})`" />
              </div>
              <div class="form-group col-6 pl-0">
                <input v-model="toPrice" class="form-control" :placeholder="`עד מחיר (${rangePriceDeal.max})`" />
              </div>
            </div>
          </div>
          <!-- <div class="col-lg-6" v-if="expand">
            <div class="form-group">
              <input v-model="toPrice" class="form-control" :placeholder="`עד מחיר (${rangePriceDeal.max})`" />
            </div>
          </div> -->
          <div class="col-lg-12 pb-2" v-if="expand">
            <div class="text-center">
              <button class="btn" @click="filterDeals">בואו נעוף</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { mapGetters } from 'vuex';
import { BFormSelect } from 'bootstrap-vue';
import imageUrlMixin from '@/utils/imageUrlMixin';

export default {
  name: 'FilterOptionPanel',
  components: {
    BFormSelect,
  },
  mixins: [imageUrlMixin],
  props: {
    resetOption: {
      type: Boolean,
      default: false,
    },
    open: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      expand: false,
      selectedDestination: null,
      selectedDealCategory: null,
      selectedMonth: null,
      fromPrice: null,
      toPrice: null,
    };
  },
  computed: {
    ...mapGetters({
      optionsDestination: 'GET_OPTIONS_DEST_APP_DEAL',
      optionsMonth: 'GET_OPTIONS_MONTH_APP_DEAL',
      optionsCategory: 'GET_OPTIONS_CATE_APP_DEAL',
      rangePriceDeal: 'GET_RANGE_PRICE',
    }),
    showInitAllOption() {
      return this.showInitDestinatin || this.showInitMonth || this.showInitCategory;
    },
    showInitDestination() {
      return this.selectedDestination !== '' && this.selectedDestination !== null;
    },
    showInitMonth() {
      return this.selectedMonth !== '' && this.selectedMonth !== null;
    },
    showInitCategory() {
      return this.selectedDealCategory !== '' && this.selectedDealCategory !== null;
    },
  },
  watch: {
    open() {
      this.expand = this.open;
    },
    resetOption() {
      if (this.resetOption) {
        this.initializeAllFilterOption();
      }
    },
    selectedDestination() {
      this.updatedFilterOption();
    },
    selectedDealCategory() {
      this.updatedFilterOption();
    },
    selectedMonth() {
      this.updatedFilterOption();
    },
    fromPrice() {
      this.updatedFilterOption();
    },
    toPrice() {
      this.updatedFilterOption();
    },
  },
  methods: {
    gotoHome() {
      this.$router.push('/app?channel=App');
    },
    showMenu() {
      this.$emit('emitMessage', {
        kind: 'toggleMenu',
        data: null,
      });
    },
    initializeAllFilterOption() {
      this.selectedDestination = null;
      this.selectedMonth = null;
      this.selectedDealCategory = null;
    },
    initializeDestOption() {
      this.selectedDestination = null;
    },
    initializeMonthOption() {
      this.selectedMonth = null;
    },
    initializeCategoryOption() {
      this.selectedDealCategory = null;
    },
    openOptionPanel() {
      this.expand = true;
    },
    closeOptionPanel() {
      this.expand = false;
    },
    updatedFilterOption() {
      this.$emit('emitMessage',
        {
          kind: 'updatedFilterOption',
          data: {
            destination: this.selectedDestination,
            category: this.selectedDealCategory,
            month: this.selectedMonth,
            fromPrice: this.fromPrice,
            toPrice: this.toPrice,
          },
        });
    },
    filterDeals() {
      this.updatedFilterOption();
      this.expand = false;
    },
  },
};
</script>

<style scoped></style>
